<template>
  <div class="schedule-wrapper__schedule --holiday">
    <div class="holiday-actions">
      <a class="button" style="min-width: 115px;" @click.prevent="RequestLeave" href="">{{ $t('Request') }}</a>
    </div>
    <div class="holiday-year">
      <p class="title">{{ $t('Your days off') }}</p>
      <ul>
        <li :data-date="new Date(event.from_date).getTime()" :key="index" v-for="(event, index) in FutureEvents">
          {{ schema[event.reason_id] }} <span>{{ GetFormattedDate(event.from_date) }} - {{ GetFormattedDate(event.to_date) }}</span>
        </li>
      </ul>
    </div>
    <div class="holiday-month">
      <div class="holiday-month__body">
        <Calendar 
          :month="month"
          :year="year"
          :events="events"
          :schema="schema"
          @update="update" />
      </div>
      <div class="holiday-month__tooltip">
        <ul class="tooltip-list">
          <li class="tooltip-list__item" :key="index" v-for="(reason, index) in schema">
            <span :class="'schedule-reason-' + index"></span> {{ reason }}
          </li> 
        </ul>
      </div>
    </div>

    <Modal modal="request_leave" :value="modal.request_leave.open" :title="$t('New request')">
      <div id="request-leave">
        <form class="form" action="#" autocomplete="off">
          <div class="form-group" id="user">
            <Select name="name" mode="single" :readonly="!IsSuperUser()" :list="user.options" :selected="[user.selected]" />
            <Select name="reason" mode="single" :list="schema" style="margin-left: 30px;" />
            <DatePicker name="start date" @date="GetDate" />
            <DatePicker name="end date" :DisabledBefore="DisabledBefore" style="margin-left: 30px;" />
            <div style="display: flex; width: 100%; padding-left: 20px;">
              <label class="checkbox" for="half-day" style="width: auto; cursor: pointer;">
                <span class="span">{{ $t('Is a half day?') }}</span>
                <input type="checkbox" id="half-day" name="half-day">
                <svg viewBox="0 0 21 21">
                  <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                </svg>
              </label>
            </div>
            <label class="full" for="notes">
              <span class="span">{{ $t('Note') }}</span>
              <textarea rows="1" name="notes" id="notes" @input="ValidateInput" :placeholder="$t('Note')"></textarea>
            </label>
          </div>
          <div class="form-action">
            <button class="button green" style="min-width: 115px;" @click.prevent="RequestNewLeave">{{ $t('Submit') }}</button>
          </div>
        </form>
      </div>
    </Modal>

  </div>
</template>

<script>
  import { Permissions }            from '@/helpers/Permissions';
  import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  import { Dates }                  from '@/mixins/Dates';
  import { BPA }                    from '@/helpers/BPA';
  import { Tool }                   from '@/helpers/Tool';
  import Calendar                   from '@/components/snippets/Calendar';
  import DatePicker                 from '@/components/snippets/DatePicker';
  import Select                     from '@/components/snippets/Select';
  import Modal                      from '@/components/snippets/Modal';

  export default {
    name: 'ScheduleHoliday',
    mixins: [Permissions, Dates, FormElementsBehaviour, BPA, Tool],
    components: {
      Calendar,
      DatePicker,
      Select,
      Modal
    },
    data() {
      return {
        DisabledBefore: null,
        date: null,
        month: null,
        year: null,
        events: [],
        employees: [],
        FutureEvents: [],
        schema: {},
        modal: {
          request_leave: {
            open: false
          }
        }
      }
    },
    created() {
      let date = new Date();
      this.date = date.getDate();
      this.month = date.getMonth();
      this.year = date.getFullYear();

      this.GetLeave();
      this.GetSchema();
      this.GetFutureLeave();
      this.GetCompanyLeave();
      this.$eventHub.$on('CloseModal', (modal_name) => {
        if (this.modal[modal_name]) this.modal[modal_name].open = false;
      });
    },
    computed: {
      locale() {
        return this.$i18n.locale;
      },
      user() {
        const data = BPA.session.auth().user;
        data.selected = '#' + data.user_id;
        data.options = {};
        return data;
      }
    },
    watch: {
      locale() {
        this.GetSchema();
        this.TranslateEvent();
      }
    },
    methods: {
      Check(required) {
        return BPA.permissions(required).length;
      },
      IsSuperUser() {
        return this.Check([/*0*/ 'admin']);
      },
      TitleCase(string, split) {
        return Tool.TitleCase(string, split);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      GetSchema() {
        let schema = BPA.api.ScheduleReasons('GET');
        for (let i in schema) schema[i] = this.$t(schema[i]);
        this.schema = schema;
      },
      async GetLeave() {
        await BPA.api.GetLeave([this.year, this.month + 1]).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let leave = response.result || [];
          if (leave.length) {
            this.events = leave[0].items;
            this.$nextTick().then(() => {
              this.TranslateEvent();
            });
          }
        }).catch(e => e);
      },
      async GetCompanyLeave() {
        await BPA.api.GetCompanyLeave([this.year, this.month + 1]).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          this.employees = response.result || [];
          this.$nextTick().then(() => {
            this.TranslateEvent();
          });
        }).catch(e => e);
      },
      async GetFutureLeave() {
        await BPA.api.GetFutureLeave().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let leave = response.result || [];
          if (leave.length) {
            this.FutureEvents = leave[0].items;
            this.$nextTick().then(() => {
              this.TranslateEvent();
            });
          }
        }).catch(e => e);
      },
      async GetUsers() {
        return await new Promise((resolve, reject) => {
          if (!this.IsSuperUser()) {
            let user_name = this.TitleCase(String(this.user.display_name).trim());
            this.user.options = {['#' + this.user.user_id]: user_name};
            return resolve();
          }
          if (Object.keys(this.user.options).length) {
            return resolve();
          }
          this.loading = true;
          BPA.api.GetUsers().then(response => {
            return BPA.api.response({response, return: 'json'});
          }).then(response => {
            this.loading = false;
            if (!response.ok) return reject();
            let result = response.result || [];
            let users = [];
            let options = {};
            for (let user of result) {
              users.push({
                id: user.id,
                name: this.TitleCase(String(user.name + ' ' + user.surname).trim())
              });
            }
            this.Alphabetize(users, 'name');
            for (let user of users) {
              options['#' + user.id] = user.name;
            }
            this.user.options = options;
            resolve();
          }).catch(e => e);
        }).catch(e => e);
      },
      TranslateEvent() {
        document.querySelectorAll('.calendar__body [data-date]').forEach(element => {
          const data = JSON.parse(element.dataset.date);
          element.title = this.schema[data.reason_id] + ' (' + this.$t((!data.approve ? 'un' : '') + 'approved') + ')';
          if (element.classList.contains('first')) element.textContent = this.schema[data.reason_id];
        });
      },
      GetFormattedDate(date) {
        date = Dates.GetTextMonthWithoutYear(new Date(date));
        return date.split(' ')[0] + ' ' + this.$t(date.split(' ')[1]);
      },
      async update(year, month) {
        this.year = year;
        this.month = month;
        this.GetLeave();
      },
      GetDate(date) {
        this.DisabledBefore = date;
      },
      RequestLeave() {
        this.GetUsers().then(() => this.modal.request_leave.open = true);
      },
      RequestNewLeave(event) {
        const form = event.target.closest('form');
        const leave = {};
        let flag = true;

        let StartDateInput = form.querySelector('input[name="start date"]');
        if (StartDateInput.value !== '') {
          let date = new Date(parseInt(StartDateInput.value));
          leave.from_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else {
          this.Error(
            StartDateInput,
            'Start date must be set'
          );
          flag = false;
        }

        let EndDateInput = form.querySelector('input[name="end date"]');
        if (EndDateInput.value !== '') {
          let date = new Date(parseInt(EndDateInput.value));
          leave.to_date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else {
          this.Error(
            EndDateInput,
            'End date must be set'
          );
          flag = false;
        }

        let ReasonInput = form.querySelector('input[name="reason"]');
        if (ReasonInput.value !== '') {
          leave.reason_id = parseInt(ReasonInput.value);
        } else {
          this.Error(
            ReasonInput,
            'Reason must be set'
          );
          flag = false;
        }

        if (this.IsSuperUser()) {
          let NameInput = form.querySelector('input[name="name"]');
          NameInput.value = NameInput.value.replace('#', '');
          if (NameInput.value !== '') {
            leave.user_id = parseInt(NameInput.value);
          } else {
            delete leave.user_id;
          }
        }
        
        let HalfDayInput = form.querySelector('input[name="half-day"]');
        leave.half_day = HalfDayInput.checked;
        
        let NotesInput = form.querySelector('textarea[name="notes"]');
        leave.note = NotesInput.value;

        if (flag) {
          BPA.api.RequestLeave(leave).then(response => {
            return BPA.api.response({response});
          }).then(response => {
            if (!response.ok) return;
            this.GetLeave();
            this.GetFutureLeave();
            this.modal.request_leave.open = false;
            this.$eventHub.$emit('ShowMessages', {
              message: 'Leave successfully requested',
              type: 'success',
              hide: 2000
            });
          }).catch(e => e);
        }
      }
    }
  }
</script>

<style scoped>
</style>
