<template>
  <div class="calendar">
    <div class="calendar__controlls">
      <a class="prev" @click.prevent="DateController(false)" href=""><span>&#8249;</span></a>
      <p @click="ShowTrigger" class="current-month">{{ $t(TextMonth) + ' ' + year }}</p>
      <a class="next" @click.prevent="DateController(true)" href=""><span>&#8250;</span></a>
      <ul id="month-trigger" v-if="active">
        <div v-if="step == null" class="controlls">
          <a class="prev" @click.prevent="GetYears(false)" href=""><span>&#8249;</span></a>
          <a class="controlls__current-month" @click.prevent="GoToCurrentMonth" href="">{{ $t('Now') }}</a>
          <a class="next" @click.prevent="GetYears(true)" href=""><span>&#8250;</span></a>
        </div>
        <li :class="item == year || item == month ? 'current' : ''" :key="item" v-for="item in TriggerContent()">
          <a @click.prevent="ChangeDates(item)" href="">{{ (step == 'month' ? $t(GetTextMonth(item)) : item) }}</a>
        </li>
      </ul>
    </div>
    <ul class="calendar__header">
      <li :key="index" v-for="(day, index) in CalendarHeader()">{{ $t(day) }}</li>
    </ul>
    <ul class="calendar__body">
      <li :class="{disabled: [0, 6].includes(date.getDay()), inactive: !date ? true : date.getMonth() != month, today: IsToday(date)}" :key="index" v-for="(date, index) in CalendarBody()">
        <p class="date">{{ date && date.getDate() }}</p>
        <div class="event" v-html="GetEvent(date)"></div>
        <p class="week">
          <span class="number" v-if="!(index % 7)">
            {{ WeekNumber(date) }}
          </span>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
  import { BPA } from '@/helpers/BPA';
  import { Dates } from '@/mixins/Dates';

  export default {
    name: 'Calendar',
    mixins: [BPA, Dates],
    data() {
      return {    
        step: null,
        active: false,
        binding: null,
        state: null,
        triggerYear: null,
        tmpYear: null,
        tmpMonth: null
      }
    },
    props: {
      year: {
        required: true
      },
      month: {
        required: true
      },
      events: {
        required: true
      },
      schema: {
        required: true
      }
    },
    computed: {
      TextMonth() {
        return Dates.GetTextMonth(this.month);
      }
    },
    destroyed() {
      window.removeEventListener('click', this.binding);
    },
    methods: {
      CalendarHeader() {
        let header = [];
        for (let i = 0; i < 7; i++) {
          let day = i + (i == 6 ? -6 : 1);
          switch (day) {
            case 0:
              day = 'sun'
              break
            case 1:
              day = 'mon'
              break
            case 2:
              day = 'tue'
              break
            case 3:
              day = 'wed'
              break
            case 4:
              day = 'thu'
              break
            case 5:
              day = 'fri'
              break
            case 6:
              day = 'sat'
              break
          }
          header.push(day);
        }
        return header;
      },
      IsToday(date) {
        const today = new Date();
        return date.getDate() == today.getDate() &&
          date.getMonth() == today.getMonth() &&
          date.getFullYear() == today.getFullYear();
      },
      WeekNumber(date) {
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
        const year = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
        return Math.ceil((((date - year) / 86400000) + 1) / 7);
      },
      CalendarBody() {
        let first_date = new Date();
        let last_date  = new Date();
        const dates = [], weeks = [];
  
        first_date = new Date(this.year, this.month, 1);
        last_date = new Date(this.year, this.month + 1, 0);
        
        for (let i = first_date.getDate(); i <= last_date.getDate(); i++) {
          const tmp_date = new Date(this.year, this.month, i);
          /*
          tmp_date.setFullYear(this.year);
          tmp_date.setMonth(this.month);
          tmp_date.setDate(i);
          tmp_date.setHours(1, 0, 0);
          */
          dates.push(tmp_date);
        }
        
        const prev_month_days = (dates[0].getDay() || 7) - 1;

        for (let i = 0; i < prev_month_days; i++) {
          const date = new Date(dates[0]);
          date.setDate(date.getDate() - 1);
          dates.unshift(date);
          //dates.unshift('');
        } 

        for (let i = 0; i < dates.length; i += 7) {
          weeks.push(dates.slice(i, i + 7));
        }

        const last_week = weeks.length - 1;
        const next_month_days = 7 - weeks[last_week].length;

        for (let i = 0; i < next_month_days; i++) {
          const date = new Date(dates[dates.length - 1]);
          date.setDate(date.getDate() + (i + 1));
          weeks[last_week].push(date);
          //weeks[last_week].push('');
        }

        return weeks.flat();
      },
      GetEvent(date) {
        if (date == '') return;
        let timestamp = date.getTime(),
          html = document.createElement('span'),
          color_flag = false,
          half_flag = false,
          first_flag = false,
          last_flag = false,
          reason = null,
          data = null,
          approved_flag = false;
        this.events.map(event => {
          if (timestamp >= new Date(event.from_date).setHours(0, 0, 0) && timestamp <= new Date(event.to_date).setHours(23, 59, 59)) {
            color_flag = true;
            reason = event.reason_id;

            if (event.half_day) {
              half_flag = true;
            }
            
            if (event.approve) {
              approved_flag = true;
            }

            data = event;
          }
          if ((date.getDate() == new Date(event.from_date).getDate() &&
            date.getMonth() == new Date(event.from_date).getMonth() &&
            date.getFullYear() == new Date(event.from_date).getFullYear())) {
            first_flag = true;
          }
          if (date.getDate() == new Date(event.to_date).getDate() &&
            date.getMonth() == new Date(event.to_date).getMonth() &&
            date.getFullYear() == new Date(event.to_date).getFullYear()) {
            last_flag = true;
          }
        });

        if (color_flag) {
          html.classList.add('color');
          html.classList.add('schedule-reason-' + reason);
        }

        if (half_flag) {
          html.classList.add('half');
        }
        
        /*
        if (first_flag || date.getDay() == 1) {
          html.classList.add('first');

          if (first_flag) {
            html.innerHTML = this.GetLabel(reason);
          }
        }

        if (last_flag || date.getDay() == 5) {
          html.classList.add('last');
        }
        */

        if (first_flag) {
          html.classList.add('first');
        }

        if (last_flag) {
          html.classList.add('last');
        }

        if (approved_flag) {
          html.classList.add('approved');
        } else {
          html.classList.add('unapproved');
        }

        if (reason !== null) {
          if (Object.keys(this.schema).includes(reason.toString())) {
            let title = this.GetLabel(reason);
            if (approved_flag) {
              title += ' (approved)';
            } else {
              title += ' (unapproved)';
            }
            html.title = title;
          }
        }

        if (data) {
          html.dataset.date = JSON.stringify(data);
        }

        return html.outerHTML;
      },
      DateController(action) {
        let tmp_year = this.year;
        let tmp_month = this.month;
        if (action) {
          if (tmp_month == 11) {
            tmp_year++;
            tmp_month = 0;
          } else {
            tmp_month++;
          }
        } else {
          if (tmp_month == 0) {
            tmp_year--;
            tmp_month = 11;
          } else {
            tmp_month = this.month - 1;
          }
        }
        this.$emit('update', 
          tmp_year,
          tmp_month
        );
      },
      ShowTrigger() {
        this.active = true;
        this.ClickHandler(true);
      },
      TriggerContent() {
        if (this.step == null) {
          let dates = [];

          this.triggerYear = this.triggerYear == null ? this.year : this.triggerYear;
          for (let i = 0; i < 12; i++) {
            dates.push(this.triggerYear + i);
          }
          
          return dates;
        } else if (this.step == 'month') {
          let dates = [];
          for (let i = 0; i < 12; i++) {
            dates.push(i);
          }
          return dates;
        }
      },
      GetYears(action) {
        if (action) {
          this.triggerYear = this.triggerYear + 12;
        } else {
          this.triggerYear = this.triggerYear - 12;
        }
      },
      ChangeDates(date) {
        if (this.step == null) {
          this.step = 'month';
          this.tmpYear = date;
        } else if (this.step == 'month') {
          this.tmpMonth = date;
          this.$emit('update',
            this.tmpYear,
            this.tmpMonth
          );
          this.ResetTrigger();
        }
      },
      ResetTrigger() {
        this.tmpYear = null;
        this.tmpMonth = null;
        this.step = null;
        this.active = false;
      },
      ValidateTrigger(event) {
        let el = event.target;
        setTimeout(() => {
          if (el.closest('#month-trigger') == null && !Object.values(event.target.classList).includes('current-month')) {
            this.ResetTrigger();
          }
        }, 5000);
      },
      GetTextMonth(date) {
        return Dates.Month(date);
      },
      ClickHandler(state) {
        if (this.binding == null) {
          window.getClicks = (event) => {
            this.ValidateTrigger(event);
          }
          this.binding = window.getClicks.bind(null);
        }
        if (!state) {
          window.removeEventListener('click', this.binding);
          this.IsBound = state;
        } else {
          window.addEventListener('click', this.binding);
          this.IsBound = state;
        }
      },
      GoToCurrentMonth() {
        let date = new Date();
        this.$emit('update',
          date.getFullYear(),
          date.getMonth()
        );
        this.ResetTrigger();
      },
      GetLabel(id) {
        return BPA.api.ScheduleReasons('GET')[id];
      }
    }
  }
</script>
